export const mapCountries = (countriesTexts) => (country) => ({
    key: country.code,
    label: countriesTexts[country.code] || '',
    value: country.code,
});
export const getCountriesListNames = (translateFn, popularCountriesList, countriesList) => {
    const countries = translateFn('profile.countries.countries_list', { ns: 'translation', returnObjects: true });
    return {
        popularCountries: popularCountriesList.map(mapCountries(countries)),
        countries: countriesList.map(mapCountries(countries)),
        countriesDictionary: countries,
    };
};
