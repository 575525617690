import { useAppSelector } from '@app/store/Hooks';
import { useGetCountriesListQuery } from '@shared/api/profile';
const useGetCountriesList = () => {
    const { popularCountriesList, countriesList, } = useAppSelector((state) => state.profile);
    const { isFetching } = useGetCountriesListQuery({}, { refetchOnMountOrArgChange: false });
    return {
        countriesList: countriesList || [],
        popularCountriesList: popularCountriesList || [],
        isLoading: isFetching,
    };
};
export default useGetCountriesList;
